import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const HEADLESS_CMS_API_URL = process.env.REACT_APP_HEADLESS_CMS_API_URL;
const perPage = 6;

export const fetchNews = createAsyncThunk(
  'news/fetchNews',
  async (page, { getState }) => {
    const state = getState();
    const newsOnPage = state.news.pages[page];

    if (newsOnPage) {
      return { page, data: newsOnPage, totalPages: state.news.totalPages };
    }

    const response = await axios.get(`${HEADLESS_CMS_API_URL}/posts`, {
      params: { page: page, per_page: perPage },
    });

    const totalPages = parseInt(response.headers['x-wp-totalpages']);

    return { page, data: response.data, totalPages };
  },
);

const initialState = {
  list: [],
  pages: {},
  currentPage: 1,
  totalPages: 0,
  loading: false,
  error: null,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        const { page, data, totalPages } = action.payload;
        state.pages[page] = data;
        state.totalPages = totalPages
        state.list = state.pages[page]; //Object.values(state.pages).flat();
        state.loading = false;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage } = newsSlice.actions;

export const getNewsById = (state, newId) => {
  return state.news.list.find((_news) => _news.id == newId);
};

export default newsSlice.reducer;
