// src/components/layout/Header.js
import React, { useEffect } from 'react';
import { Person } from 'react-bootstrap-icons';

import { useBasePath } from 'utils/global';

const Header = () => {
  const routesForBackground = [
    '/knowledge',
    '/knowledge/news',
    '/pricing',
    '/calendly',
    '/privacy-policy',
    '/imprint',
    '/tos',
  ];

  const location = useBasePath();
  const isBackground = routesForBackground.includes(location);

  useEffect(() => {
    const handleMenuClick = () => {
      const menu = document.querySelector('.menu');
      menu.classList.toggle('open');
    };

    const handleHamburgerClick = () => {
      const hamburger = document.querySelector('.hamburger');
      const navMenu = document.querySelector('.navigation');
      hamburger.classList.toggle('active');
      navMenu.classList.toggle('active');
    };

    const handleScroll = () => {
      const head = document.querySelector('.dog');
      head.classList.toggle('fixeds', window.scrollY > 40);
    };

    // Add event listeners
    document.querySelector('.menu').addEventListener('click', handleMenuClick);
    document
      .querySelector('.hamburger')
      .addEventListener('click', handleHamburgerClick);
    window.addEventListener('scroll', handleScroll);

    // Clean up event listeners on component unmount
    return () => {
      document
        .querySelector('.menu')
        .removeEventListener('click', handleMenuClick);
      document
        .querySelector('.hamburger')
        .removeEventListener('click', handleHamburgerClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className="container-fluid dog z-[90]"
      style={
        isBackground
          ? { paddingBottom: 10, backgroundColor: 'rgba(0, 0, 0, 0.8)' }
          : {}
      }
    >
      <div className="container mx-auto pl-4 pr-8 lg:pl-4">
        <div className="row">
          <div className="cheader">
            <a title="" href="/">
              <img
                src={require('../../assets/images/logo.png')}
                className="img-fluid logo"
                alt="Darknetsearch"
              />
            </a>
            <div className="navigation">
              <ul>
                <li className="has-dropdown">
                  <a title="" href="/solution">
                    Solution
                  </a>
                  <ul className="submenu">
                    <li>
                      <a href="/solution/attack-surface-discover">
                        Attack Surface Discovery
                      </a>
                    </li>
                    <li>
                      <a href="#">Hacker Forum Analysis</a>
                    </li>
                    <li>
                      <a href="#">Open Cloud Container Exposure Detectio</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a title="" href="/pricing">
                    Pricing
                  </a>
                </li>
                <li>
                  <a title="" href="/knowledge">
                    Knowledge
                  </a>
                </li>
                <li>
                  <a title="" href="/about-us">
                    About us
                  </a>
                </li>
                <li>
                  <a title="" href="/login">
                    <div className="flex items-center">
                      <span className="me-2">
                        <Person />
                      </span>{' '}
                      Login
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="menu hamburger">
              <div className="icon"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
