import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack'
import { router } from 'routes';

function App() {
  return (
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  );
}

export default App;
